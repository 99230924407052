import { getRateByServiceType } from "./ratecard";
import { fetchFuelData, getFuelPercentage } from "./fuel";
import { calculateVAT, findVATValue } from "./vat";
import { parse } from "papaparse";
import { WorkBook, read, utils, SSF } from "xlsx-js-style";
import { HEADER, RECEIVER_INFORMATION_HEADER, Row } from "./spreadsheet";
import {
  ACCOUNT_INDEX,
  BILLED_WEIGHT_INDEX,
  CONTAINER_TYPE_INDEX,
  CURRENCY,
  CurrencyValue,
  DESTINATION_INDEX,
  DIRECTION_INDEX,
  EXPORT_TRACKING_NUMBER_INDEX,
  IMPORT_TRACKING_NUMBER_INDEX,
  INVOICE_UPS_INDEX,
  ORIGIN_INDEX,
  PACKAGE_COUNT_INDEX,
  RECEIVER_ADDRESS,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  REFERENCE_ONE_INDEX,
  REFERENCE_TWO_INDEX,
  SERVICE_TYPE_INDEX,
  SHIPMENT_DATE_INDEX,
  SHIPPER_INDEX,
  UNKNOWN_INDEX_1,
  UNKNOWN_INDEX_2,
  UNKNOWN_INDEX_5,
  VAT_RATE,
} from "./constants";
import { execute, fetchSchema } from "./surcharges";
import { createSheet, extractDate, generateSumRow, round } from "./helpers";

export async function parseCsv<T = string[]>(
  file: string | File
): Promise<T[]> {
  return new Promise((resolve, reject) => {
    parse<T>(file, {
      dynamicTyping: true,
      complete(results: { data: T[] }) {
        resolve(results.data);
      },
    });
  });
}

export async function parseXlsxFile(file: File): Promise<WorkBook> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = read(data, { raw: true, type: "binary" });
      resolve(workbook);
    };
    reader.readAsBinaryString(file);
  });
}

export async function processData(
  csvFiles: FileList,
  ratecardFile: File,
  includeReceiverInfo: boolean,
  { rate: currencyRate, format: currencyFormat }: CurrencyValue = CURRENCY.CZK
) {
  const ratecardParsed = await parseXlsxFile(ratecardFile);
  const fuelData = await fetchFuelData();
  const mappingSchema = await fetchSchema();

  const surchargesHeader = mappingSchema.map((m) => m.name);

  const invoiceData = new Map<string, string[][]>();
  const invoicesParsed: string[][] = [];

  for (const file of csvFiles) {
    let parsedCsv = await parseCsv(file);
    // If the last row is empty, remove it
    if (parsedCsv[parsedCsv.length - 1].length === 1) parsedCsv.pop();
    invoicesParsed.push(...parsedCsv);
  }

  const sheetData: Row[] = [];
  const checkSheetData: Row[] = [];

  invoicesParsed.forEach((row) => {
    // Get the tracking number from the row
    const direction = row[DIRECTION_INDEX];
    const trackingNumber =
      direction.toLowerCase() === "i"
        ? row[IMPORT_TRACKING_NUMBER_INDEX]
        : row[EXPORT_TRACKING_NUMBER_INDEX];

    const mapKey = `${trackingNumber}-${direction}`;

    // If the map does not have the tracking number, add it to the map
    if (!invoiceData.has(mapKey)) {
      invoiceData.set(mapKey, [row]);
    } else {
      // Otherwise, push the row to the array of rows with the same tracking number
      invoiceData.get(mapKey)?.push(row);
    }
  });

  // Iterate over the map of invoices
  invoiceData.forEach((invoices, key) => {
    const [trackingNumber, direction] = key.split("-");

    const invoiceFirst = invoices[0];

    // If the invoice has a late payment, skip it
    const latePaymentData = invoices.find(
      (invoice) => invoice[UNKNOWN_INDEX_1] === "MSC"
    );

    if (latePaymentData) {
      return;
    }

    // Find the rows that has the column with X index equal to Y
    const referenceData = invoices.find(
      (invoice) => invoice[UNKNOWN_INDEX_1] === "FRT"
    );
    const containerTypeData = invoices.find(
      (invoice) =>
        invoice[UNKNOWN_INDEX_2] === "SHP" && invoice[UNKNOWN_INDEX_1] === "FRT"
    );
    const destinationData =
      containerTypeData ??
      invoices.find(
        (invoice) =>
          invoice[UNKNOWN_INDEX_2] === "RTN" &&
          invoice[UNKNOWN_INDEX_1] === "FRT"
      );
    const fuelCheckData = invoices.find(
      (invoice) => invoice[UNKNOWN_INDEX_1] === "FSC"
    );

    const shipmentDate = extractDate(invoiceFirst[SHIPMENT_DATE_INDEX]);

    const account = invoiceFirst[ACCOUNT_INDEX];
    const invoicePoolside = "";
    const invoiceUPS = invoiceFirst[INVOICE_UPS_INDEX];
    const referenceOne = referenceData
      ? referenceData[REFERENCE_ONE_INDEX]
      : "";
    const referenceTwo = referenceData
      ? referenceData[REFERENCE_TWO_INDEX]
      : "";
    const destinationCountry = destinationData
      ? destinationData[DESTINATION_INDEX]
      : "";
    const shipper = destinationData ? destinationData[SHIPPER_INDEX] : "";
    const origin = destinationData ? destinationData[ORIGIN_INDEX] : "";
    const billedWeightRaw = destinationData
      ? destinationData[BILLED_WEIGHT_INDEX]
      : "0";
    const packageCount = destinationData
      ? Number(destinationData[PACKAGE_COUNT_INDEX])
      : 0;
    const serviceType = destinationData
      ? destinationData[SERVICE_TYPE_INDEX]
      : "";
    const containerType = containerTypeData
      ? containerTypeData[CONTAINER_TYPE_INDEX]
      : "";
    const week = shipmentDate.week();
    const fuelCheck = fuelCheckData
      ? Number(fuelCheckData[UNKNOWN_INDEX_5])
      : 0;

    const receiverCompanyName = invoiceFirst[RECEIVER_COMPANY_NAME];
    const receiverAddress = invoiceFirst[RECEIVER_ADDRESS];
    const receiverCity = invoiceFirst[RECEIVER_CITY];

    const billedWeight = Number(
      typeof billedWeightRaw === "number"
        ? billedWeightRaw
        : billedWeightRaw.replace(",", ".")
    );

    // Get the net freight
    const rateCountryInput =
      direction.toLowerCase() === "i" ? origin : destinationCountry;
    const netFreight = getRateByServiceType(
      ratecardParsed,
      serviceType,
      packageCount,
      billedWeight,
      rateCountryInput,
      direction,
      containerType
    );

    const netFreightCheck = destinationData
      ? round(Number(destinationData[UNKNOWN_INDEX_5]))
      : 0;

    const surchargesMap = execute(
      invoices,
      mappingSchema,
      direction.toLowerCase()
    );

    const surchargesCheckMap = surchargesMap;

    // Hardcoded conditions (this might be refactored to be more configurable in the future)

    const peakCorrection = surchargesMap.get("peak_correction");
    if (peakCorrection && peakCorrection.value > 0) {
      const peakCom = surchargesMap.get("peak_com");
      surchargesMap.set("peak_com", { ...peakCom!, value: 0 });

      const peakRez = surchargesMap.get("peak_rez");
      surchargesMap.set("peak_rez", { ...peakRez!, value: 0 });
    }

    const paperComInvoice = surchargesMap.get("paper_com_invoice");
    const vatFees = findVATValue(invoices, "FEES");

    surchargesMap.set("paper_com_invoice", {
      ...paperComInvoice!,
      value: vatFees + (paperComInvoice?.value ?? 0),
      checkValue: vatFees + (paperComInvoice?.checkValue ?? 0),
    });


    // TODO: This could be refactored to more reusable code
    //       and remove the duplication for check and output

    const surchargesList = [...surchargesMap.values()];
    const surchargesCheckList = [...surchargesCheckMap.values()];

    // Get the fuel percentage for the week
    const fuelPercentage = getFuelPercentage(fuelData, serviceType, week);
    // Calculate the fuel surcharge value using the netFreight and
    // surcharges values with includeInFuel set to true
    const fuel =
      [
        netFreight,
        ...surchargesList.filter((v) => v.includeInFuel).map((v) => v.value),
      ].reduce((p, c) => p + c) * fuelPercentage;

    // Create list of surcharges values
    const surchargesBase = surchargesList.map((v) => v.value);
    const surchargesCheckBase = surchargesCheckList.map((v) => v.checkValue);

    // Create a new object with the same keys as the surcharges map and the values
    // divided by the currency rate
    const surchargesPairs = Object.fromEntries(
      [...surchargesMap.entries()].map(([k, v]) => [
        k,
        round(v.value / currencyRate),
      ])
    );

    const surchargesCheckPairs = Object.fromEntries(
      [...surchargesCheckMap.entries()].map(([k, v]) => [
        k,
        round(v.checkValue / currencyRate),
      ])
    );

    // Calculate the surcharges
    const surcharges = [...surchargesBase, fuel].reduce(
      (p, c) => Number(p) + Number(c)
    );

    const surchargesCheck = [...surchargesCheckBase, fuelCheck].reduce(
      (p, c) => Number(p) + Number(c)
    );

    // Calculate the net total
    const netTotal = netFreight + surcharges;

    const netTotalCheck = netFreightCheck + surchargesCheck;

    // Calculate the VAT
    const vatSum = calculateVAT(invoices);

    // Calculate the VAT amount with the VAT rate
    const vat = vatSum === 0 ? 0 : netTotal * VAT_RATE;

    const vatCheck = vatSum === 0 ? 0 : netTotalCheck * VAT_RATE;

    // Calculate the grand total
    const grandTotal = netTotal + vat;

    const grandTotalCheck = netTotalCheck + vatCheck;

    // Push the data to the sheet data map, rounding the numbers to
    // 2 decimal places and multiplying them by the currency rate
    const baseRow = {
      trackingNumber: trackingNumber,
      account: account,
      shipmentDate: shipmentDate.toDate(),
      invoicePoolside: invoicePoolside,
      invoiceUps: invoiceUPS,
      reference1: referenceOne,
      reference2: referenceTwo,
      destination: destinationCountry,
      shipper: shipper,
      origin: origin,
      direction: direction,
      billedWeight: billedWeight,
      package: packageCount,
      serviceType: serviceType,
      typeOfPackaging: containerType,
      netFreight: round(netFreight / currencyRate),
      surcharges: round(surcharges / currencyRate),
      netTotal: round(netTotal / currencyRate),
      vat: round(vat / currencyRate),
      grandTotal: round(grandTotal / currencyRate),
      week: week,
      fuelPercent: SSF.format("0.00%", fuelPercentage),
      fuel: round(fuel / currencyRate),
    };

    sheetData.push({
      ...baseRow,
      ...surchargesPairs,
      ...(includeReceiverInfo && {
        receiverCompanyName: receiverCompanyName,
        receiverAddress: receiverAddress,
        receiverCity: receiverCity,
      }),
    });

    checkSheetData.push({
      ...baseRow,
      netFreight: round(netFreightCheck / currencyRate),
      surcharges: round(surchargesCheck / currencyRate),
      netTotal: round(netTotalCheck / currencyRate),
      vat: round(vatCheck / currencyRate),
      grandTotal: round(grandTotalCheck / currencyRate),
      fuel: round(fuelCheck / currencyRate),
      ...surchargesCheckPairs,
      ...(includeReceiverInfo && {
        receiverCompanyName: receiverCompanyName,
        receiverAddress: receiverAddress,
        receiverCity: receiverCity,
      }),
    });
  });

  const header = [...HEADER, "Fuel %", "Fuel", ...surchargesHeader];

  if (includeReceiverInfo) {
    header.push(...RECEIVER_INFORMATION_HEADER);
  }

  const sheet = createSheet(sheetData, header);
  const checkSheet = createSheet(checkSheetData, header);

  // Add sum for net total, vat and grand total
  const startCell = `Q${sheetData.length + 3}`; // Q is the column for net total which is the first column

  utils.sheet_add_aoa(
    sheet,
    [generateSumRow(sheetData.length, currencyFormat)],
    {
      origin: startCell,
    }
  );

  utils.sheet_add_aoa(
    checkSheet,
    [generateSumRow(sheetData.length, currencyFormat)],
    {
      origin: startCell,
    }
  );

  const workbook = utils.book_new();
  const checkWorkbook = utils.book_new();

  utils.book_append_sheet(workbook, sheet, "Sheet1");
  utils.book_append_sheet(checkWorkbook, checkSheet, "Sheet1");

  return { workbook, check: checkWorkbook };
}
