import { utils } from "xlsx-js-style";
import { parseCsv } from ".";
import { MAPPING_GOOGLE_SHEET_ID } from "./constants";
import { parseNumber } from "./helpers";

enum ResultMethod {
  SUM = "SUM",
  FIRST = "FIRST",
}

interface Mapping {
  columnName: string;
  columnKeyValue: string[];
  dataColumnName: string;
  resultMethod: ResultMethod;
}

export interface RowMapping {
  key: string;
  name: string;
  includeInFuel: boolean;
  exportMapping: Mapping;
  importMapping?: Mapping;
  discount: number;
  addition: number;
}

export type MappingResult = {
  name: RowMapping["name"];
  value: number;
  checkValue: number; // Original value before discount
  includeInFuel: RowMapping["includeInFuel"];
};

export async function fetchSchema(): Promise<RowMapping[]> {
  const response = await fetch(
    "https://docs.google.com/spreadsheets/d/" +
      MAPPING_GOOGLE_SHEET_ID +
      "/export?format=csv"
  ).then((res) => res.text());

  // Parse the string and remove the first row (header)
  const data = await parseCsv<string[]>(response).then((res) => res.slice(1));

  return data.map((row) => {
    // If importMapping is not defined, set it to undefined
    const importMapping =
      row[5] && row[6] && row[7] && row[8]
        ? {
            columnName: row[5],
            columnKeyValue: row[6].split(","),
            dataColumnName: row[7],
            resultMethod: row[8] as ResultMethod,
          }
        : undefined;

    const discount = row[10] ? parseNumber(row[10]) : 1;
    const addition = row[11] ? parseNumber(row[11]) : 0;

    return {
      key: row[0]
        .toLowerCase()
        .replace(" - ", " ")
        .replace(".", "")
        .replace(/\s/g, "_"),
      name: row[0],
      includeInFuel: row[9]?.toLowerCase() === "yes",
      discount: isNaN(discount) ? 1 : discount,
      addition: isNaN(addition) ? 0 : addition,
      exportMapping: {
        columnName: row[1],
        columnKeyValue: row[2].split(","),
        dataColumnName: row[3],
        resultMethod: row[4] as ResultMethod,
      },
      importMapping,
    };
  });
}

export function execute(
  invoices: string[][],
  schema: RowMapping[],
  direction: string
): Map<RowMapping["key"], MappingResult> {
  let resultMap = new Map<RowMapping["key"], MappingResult>();

  schema.forEach(
    ({ key, name, exportMapping, importMapping, includeInFuel, discount, addition }) => {
      let mapping: Mapping;

      // If current mapping have importMapping and direction is import
      // use importMapping, otherwise use exportMapping
      if (importMapping && direction === "i") {
        mapping = importMapping;
      } else {
        mapping = exportMapping;
      }

      // Decode column name to number (A => 0, B => 1, etc.)
      const columnIndex = utils.decode_col(mapping.columnName);
      const dataColumnIndex = utils.decode_col(mapping.dataColumnName);

      let value;

      if (mapping.resultMethod === ResultMethod.FIRST) {
        // Find the first invoice that matches the columnKeyValue

        const data = invoices.find((invoice) =>
          invoice[columnIndex]
            ? mapping.columnKeyValue.includes(invoice[columnIndex].toString())
            : false
        );

        value = data ? Number(data[dataColumnIndex]) : 0;
      } else {
        // Find all invoices that match the columnKeyValue
        const data = invoices.filter((invoice) =>
          invoice[columnIndex]
            ? mapping.columnKeyValue.includes(invoice[columnIndex].toString())
            : false
        );

        value =
          data.length > 0
            ? data.reduce((p, c) => {
                return p + Number(c[dataColumnIndex]);
              }, 0)
            : 0;
      }

      // If in input csv data is zero, then we fill in addition from schema, if there is any
      const additionData = invoices.filter((invoice) =>
        invoice[columnIndex]
          ? mapping.columnKeyValue.includes(invoice[columnIndex].toString())
          : false
      );

      let resultValue = value * discount;

      if(additionData.length){
        if(Number(additionData[0][dataColumnIndex]) === 0 && addition){
          resultValue = addition
        }
      }

      resultMap.set(key, {
        name: name,
        includeInFuel,
        value: resultValue,
        checkValue: value,
      } satisfies MappingResult);
    }
  );

  return resultMap;
}
