import { WorkBook, WorkSheet, utils } from "xlsx-js-style";

const RATE_CARD_START = 2;

export function getRateByServiceType(
  ratecard: WorkBook,
  serviceType: string,
  packageCount: number,
  billedWeight: number,
  country: string,
  direction: string,
  containerType: string
) {
  const sheets = new Map<string, WorkSheet>();

  ratecard.SheetNames.forEach((sheet) => {
    sheets.set(sheet.toLowerCase(), ratecard.Sheets[sheet]);
  });

  const directionLong = direction.toLowerCase() === "e" ? "export" : "import";

  const SHEET_PAIRS = [
    {
      serviceTypes: [
        "tb express saver",
        "ww express saver",
        "dom. express saver",
      ],
      shortName: "express_saver",
      sheetName: `ups_${directionLong}_express saver`,
    },
    {
      serviceTypes: ["tb standard", "dom. standard", "ww standard"],
      shortName: "standard",
      sheetName:
        packageCount === 1
          ? `ups_${directionLong}_standart single`
          : `ups_${directionLong}_standart multi`,
    },
    {
      serviceTypes: ["ww express", "dom. express", "tb express"],
      shortName: "express",
      sheetName: `ups_${directionLong}_express`,
    },
    {
      serviceTypes: ["tb expedited", "ww expedited"],
      shortName: "expedited",
      sheetName: `ups_${directionLong}_expedited`,
    },
  ];

  const sheet = SHEET_PAIRS.find((v) =>
    v.serviceTypes.includes(serviceType.toLowerCase())
  );

  const sheetName = sheet?.sheetName;

  if (!sheetName || !sheets.has(sheetName)) return 0;

  const rates = sheets.get(sheetName);
  if (!rates) return 0;

  const rate = Number(
    findRate(rates, billedWeight, country, containerType, sheet?.shortName)
  );

  if (isNaN(rate)) return 0;

  return rate;
}

function findRate(
  rates: WorkSheet,
  billedWeight: number,
  country: string,
  containerType: string,
  shortName: string
) {
  const data = utils.sheet_to_json<(string | number)[][]>(rates, { header: 1 });

  // Find all weights (start and end is the range of rows to search)
  const weights = data.slice(RATE_CARD_START).map((v) => Number(v[0]));

  // If container type is LTR, service type is Express or Express Saver
  // and billed weight is <= 0.5, return the rate for 0 Kg
  if (
    containerType.toLowerCase() === "ltr" &&
    ["express", "express_saver"].includes(shortName) &&
    billedWeight <= 0.5
  ) {
    const columnIndex = findCountryIndex(data, country);
    const rowIndex = data.findIndex((v) => Number(v[0]) === 0);

    return data[rowIndex][columnIndex];
  }

  // Find the weight closest to the billed weight
  const closestWeight = weights.reduce((p, c) => {
    const diffC = c - billedWeight;
    const diffP = p - billedWeight;

    if (
      (diffC >= 0 && diffC < diffP) ||
      (diffC >= 0 && diffC === diffP && c > p)
    ) {
      return c;
    } else {
      return p;
    }
  }, Number.POSITIVE_INFINITY);

  if (closestWeight === Number.POSITIVE_INFINITY) return 0;

  // Find the index of the weight closest to the billed weight
  const rowIndex = data.findIndex((v) => Number(v[0]) === closestWeight);

  // Find the index of the country
  const columnIndex = findCountryIndex(data, country);

  // Return the rate
  return data[rowIndex][columnIndex];
}

function findCountryIndex(data: (string | number)[][][], country: string) {
  // Find the index of the country
  return data[1].findIndex((v) => v.toString().split(", ").includes(country));
}
